import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [{}];
  public lineChartDataHum: ChartDataSets[] = [{}]; /* = [{fill: false, "label":"tempOg","data":[{"t":"1560085753","y":22.22},{"t":"1560086116","y":22.22},{"t":"1560086333","y":22.22},{"t":"1560086518","y":22.22},{"t":"1560086688","y":22.22},{"t":"1560086857","y":22.31}]},{"label":"tempWein","data":[{"t":"1560085753","y":19.26},{"t":"1560086116","y":19.26},{"t":"1560086333","y":19.26},{"t":"1560086518","y":19.26},{"t":"1560086688","y":19.26},{"t":"1560087042","y":19.26}], fill: false}];*/
  
  
  /*[
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  ];*/
  public lineChartLabels: Label[] = [];
  public lineChartLabelsHum: Label[] = []; //[new Date(1560085753*1000).toLocaleString(), new Date(1560086116*1000).toLocaleString(), 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    spanGaps: true,
    responsive: false,
    animation: null,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          fill: false,
          /*label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }*/
        },
      ],
    },
  };

  public currData = [];

  public lineChartColors: Color[] = [];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public type = "day";
  public selectOptions: string[] = ["day", "hour", "week", "month"];
  //public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(private httpClient: HttpClient) { }

  public changeTypeHandler(event){
    this.getData();
    this.getDataHum();
  }

  public getColors(){
    return this.httpClient.get<Color[]>('https://home.haefliger.ch/assets/colors.php').subscribe( data => {
      this.lineChartColors = data;
      let x = [];
    });
  }
  public getData(){
    var type = this.type;
    return this.httpClient.get<ChartDataSets[]>(`https://home.haefliger.ch/assets/temp.php?type=${type}`).subscribe( data => {
      this.lineChartData = data;
      let x = [];
      //update labels
      for(let d of data[0].data){
        x.push(this.dateAsYYYYMMDDHHNNSS(new Date(d["t"]*1000)));
      }
      this.lineChartLabels = x;
      console.log('data gotten');
    });
  }

  public getDataHum(){
    var type = this.type;
    return this.httpClient.get<ChartDataSets[]>(`https://home.haefliger.ch/assets/hum.php?type=${type}`).subscribe( data => {
      this.lineChartDataHum = data;
      let x = [];
      //update labels
      for(let d of data[0].data){
        x.push(this.dateAsYYYYMMDDHHNNSS(new Date(d["t"]*1000)));
      }
      this.lineChartLabelsHum = x;
      console.log('data gotten');
    });
  }
  public getCurrentValue(){
    var type = this.type;
    return this.httpClient.get<ChartDataSets[]>(`https://home.haefliger.ch/assets/curr.php`).subscribe( data => {
      this.currData = data;
      console.log(this.currData);
    });
  }

  public leftpad(val, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
          + String(val)).slice(String(val).length);
  }

  public dateAsYYYYMMDDHHNNSS(date): string {
    return this.leftpad(date.getHours(), 2)
              + ':' + this.leftpad(date.getMinutes(), 2)
              + ':' + this.leftpad(date.getSeconds(), 2);
  }
  
  ngOnInit() {
    const secondsCounter = interval(60000);
    const secondsCounterCurr = interval(5000);
    this.getCurrentValue();
    this.getColors();
    this.getData();
    this.getDataHum();
    secondsCounterCurr.subscribe(n => {
      this.getCurrentValue();
    });
    secondsCounter.subscribe(n => {
      console.log(`get data ${n}`);
      this.getData();
      this.getDataHum();
    })
  }
}